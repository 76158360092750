<template>
  <layout-main>
    <div class="survey">
      <!-- Header -->
      <div class="subheader">
        <div class="subheader-main">
          <h3>
            {{ localOffice.label }}
          </h3>
          <span class="separator-line"></span>
          <div class="breadcrumbs" v-if="!isLoading">
            <router-link to="/offices" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
            <span class="separator-dot"></span>
            <router-link to="/offices" class="breadcrumbs-link"> {{ $t('labels.duty-tasks') }}</router-link>
            <span class="separator-dot"></span>
            <router-link :to="'/offices?officeFilter=[&quot;' + localOffice.office.label + '&quot;]'"
                         class="breadcrumbs-link"
            >{{ localOffice.office.label }}
            </router-link>
            <span class="separator-dot"></span>
            <router-link :to="'/offices/localoffice/' + id + '/overview'" class="breadcrumbs-link">{{
                localOffice.label
              }}
            </router-link>
          </div>
        </div>
      </div>

      <div class="widget overview" v-if="!isLoading">
        <div class="widget-body pr-0 pl-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-3">
                <div class="list overview-middle">
                  <div class="list-item">
                    <div style="font-size: 1.6rem;" class="fw-500">{{ localOffice.office.label }}</div>
                  </div>
                  <div class="list-item">
                    <label>{{ $t('labels.local-office') }}</label>
                    <span>{{ localOffice.label }}</span>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="list overview-middle">
                  <div class="list-item">
                    <label>{{ $t('labels.police-region') }}</label>
                    <span>{{ localOffice.police_region_list }}</span>
                  </div>
                  <div class="list-item">
                    <label>{{ $t('labels.county') }}</label>
                    <span>{{ localOffice.county_list }}</span>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="list overview-middle">
                  <div class="list-item">
                    <label>{{ $t('labels.municipalities') }}</label>
                    <span>{{ localOffice.municipality_list }}</span>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="list overview-middle">
                  <!--<div class="list-item">
                    <label>Stadsdelar</label>
                    <span>{{ localOffice.district_list }}</span>
                  </div>-->
                  <div class="list-item">
                    <label>{{ $t('labels.phone') }}</label>
                    <span><i class="fas fa-phone"></i> {{ localOffice.phone_1 }}</span>
                  </div>

                  <div class="list-item">
                    <label>{{ $t('labels.mobile') }}</label>
                    <span><i class="fas fa-mobile-alt"></i> {{ localOffice.mobile_1 }}</span>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="list overview-last">
                  <div class="list-item">
                    <label>{{ $t('labels.opening-hours') }}</label>
                    <span>{{ localOffice.opening_hours }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widget-footer with-tabs">
          <ul class="nav nav-tabs nav-tabs-line" role="tablist">


            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'localoffice.overview', params: { id: id } }">{{ $t('labels.overview') }}
              </router-link>
            </li>

            <v-guard :permissions="['office-section-contact-view']">
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'localoffice.contact', params: { id: id } }">{{ $t('labels.contact-information') }}
                </router-link>
              </li>
            </v-guard>
            <v-guard :permissions="['office-section-geography-view']">
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'localoffice.geography', params: { id: id } }">{{ $t('labels.geography') }}
                </router-link>
              </li>
            </v-guard>
            <v-guard :permissions="['office-section-openinghours-view']">
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'localoffice.openinghours', params: { id: id } }">{{ $t('labels.opening-hours') }}
                </router-link>
              </li>
            </v-guard>
            <v-guard :permissions="['office-section-quality-view']">
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'localoffice.quality', params: { id: id } }">{{ $t('labels.quality') }}
                </router-link>
              </li>
            </v-guard>
            <v-guard :permissions="['office-section-currentinformation-view']">
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'localoffice.information', params: { id: id } }"> {{ $t('labels.current-information') }}
                </router-link>
              </li>
            </v-guard>
            <v-guard :permissions="['office-section-statistics-view']">
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'localoffice.statistics', params: { id: id } }"> {{ $t('labels.statistics') }}
                </router-link>
              </li>
            </v-guard>
            <v-guard :permissions="['office-section-boardmembers-view']">
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'localoffice.board', params: { id: id } }">{{ $t('labels.board') }}
                </router-link>
              </li>
            </v-guard>
            <v-guard :permissions="['office-section-coordinators-view']">
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'localoffice.coordinator', params: { id: id } }">{{ $t('labels.coordinator') }}
                </router-link>
              </li>
            </v-guard>
            <v-guard :permissions="['office-section-volunteers-view']">
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'localoffice.support', params: { id: id } }">{{ $t('labels.supporters') }}
                </router-link>
              </li>
            </v-guard>
            <li class="nav-item d-none">
              <router-link class="nav-link" :to="{ name: 'localoffice.history', params: { id: id } }">{{ $t('labels.history') }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>

      <div style="position: relative;">
        <transition name="slide-right">
          <router-view v-if="!isLoading" class="child-view" :key="id" :localOffice="localOffice"
                       @updateLocalOffice="updateLocalOffice"></router-view>
        </transition>
      </div>
    </div>
  </layout-main>
</template>

<script>
import BojAPI from '@/api/boj';
import LayoutMain from '@/views/Layouts/Main';

export default {
  name: 'localoffice-page',
  components: {
    LayoutMain,
  },
  props: {
    id: Number,
  },
  data() {
    return {
      isLoading: true,
      localOffice: {
        label: '',
      },
    };
  },

  beforeRouteEnter(to, from, next) {
    BojAPI.getLocalOffice(to.params.id)
        .then((response) => {
          next((vm) => vm.setData(response.data));
        })
        .catch((error) => {
        });
  },
  beforeRouteUpdate(to, from, next) {
    BojAPI.getLocalOffice(to.params.id).then((response) => {
      this.setData(response.data);
      next();
    });
  },

  methods: {
    updateLocalOffice(localOffice) {
      this.setData({data: localOffice});
    },

    setData(payload) {
      this.localOffice = payload.data;
      this.isLoading = false;
    },
  },
};
</script>
